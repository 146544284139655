import React, {useContext} from 'react'
import  {GlobalContext}  from  '../../data/GlobalContext';


function HomeBlockTwo(props) {
    const dbData = useContext(GlobalContext);
    return (
        <section className="working-process-section grey-bg">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-10 order-lg-1 order-2">
                        <div className="process-text">
                            {/* Section Title */}
                            <div className="section-title left-border mb-30">
                                <span className="title-tag">{dbData.dbSlogan[3].slogan}</span>
                                <h2 className="title">{dbData.dbSlogan[1].slogan}</h2>
                            </div>
                            <p>
                                {dbData.dbHome[1].text}
                            </p>
                            {/* process-loop */}
                            <div className="process-loop">
                                {dbData.dbValues.map((item, i) => (
                                    <div key={i} className="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                        <div className="icon">
                                            <i className="fal fa-hard-hat"></i>
                                            <span>{i + 1}</span>
                                        </div>
                                        <div className="content">
                                            <h4>{item.title}</h4>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10 order-lg-2 order-1">
                        <div className="process-video bg-img-c" style={{ backgroundImage: `url('${dbData.stock[2]}')` }}>
                            <div className="video bg-img-c wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="400ms" style={{  backgroundImage: `url('${dbData.stock[4]}')` }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="working-circle" />
        </section>
    );
}

export default HomeBlockTwo;
